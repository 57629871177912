import React from 'react'
import LazyLoad from 'react-lazyload'
import FeaturedText from './FeaturedText'

const LazyFeaturedText = ({lazy, ...props}) => {
  console.log('lazy', lazy);

  return lazy ? (
    <LazyLoad height={300} once offset={100}>  
      <FeaturedText {...props} />
    </LazyLoad> 
  ) : (
    <FeaturedText {...props} />
  )
}

export default LazyFeaturedText