import React from 'react'
import SVG from 'react-inlinesvg';
import styled from 'styled-components'
import FeaturedTitle from '../components/styled/FeaturedTitle'
import AnimateIn from '../components/motion/AnimateIn'
import { tertiaryTitleFont } from '../styles/fonts'
import breakpoints from "../styles/breakpoints";

const StyledSVG = styled(SVG)`
  height: 100%;
  max-width: 125px;

  ${breakpoints.md} {
    max-width: 200px;
  }

  > path {
    fill: currentColor;
  }
`

const StyledText = styled.p`
  font-family: ${tertiaryTitleFont};
  font-weight: 400;

  ${breakpoints.md} {
    font-size: 1.25rem;
  }

  ${breakpoints.lg} {
    font-size: 1.5rem;
  }
`

const FeaturedText = ({title, description, image}) => (
  <AnimateIn>
    <StyledSVG src={image} />
    <FeaturedTitle>
      {title}
    </FeaturedTitle>
    <StyledText>
      {description}
    </StyledText>
  </AnimateIn>
)

export default FeaturedText