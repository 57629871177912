import React from "react";
import Container from '../components/styled/Container'

import Row from '../components/styled/Row'
import Col from '../components/styled/Col'
import styled from 'styled-components'
import space from "../styles/space";
import LazyLoad from 'react-lazyload'
import AnimateIn from '../components/motion/AnimateIn'
import Callout from '../components/Callout'
import LazyFeaturedText from '../components/LazyFeaturedText'
import specialFeatures from '../config/specialFeatures'

const StyledRow = styled(Row).attrs({align: 'center'})`
  min-height: 80vh;
  padding: ${space.xl}px 0px;
`

const StyledCallout = styled(Callout)`
  margin-bottom: ${space.xxl}px;
`

const StyledCol = styled(Col)`
  height: 100%;
`

const RegularPage = () => (
  <Container>

    {specialFeatures.map((feature, i) => (
      <StyledRow key={'row'+i}>
        <StyledCol center>
          <LazyFeaturedText {...feature} lazy={i > 0}/>
        </StyledCol>
      </StyledRow>
    ))}
  
    <StyledRow>
      <StyledCol>
        <LazyLoad height={100} once>
          <AnimateIn>
            <StyledCallout
              title="Looking for a modern web developer?"
              description="I've been building web apps for 5 years. Let's get in touch and talk about what problems you're sovling, and how I can help."
              buttonLabel="Let's chat!"
            />
          </AnimateIn>
        </LazyLoad>
      </StyledCol>
    </StyledRow>
  </Container>
);

export default RegularPage;
