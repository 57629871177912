import styled from 'styled-components'
import {primaryTitleFont} from '../../styles/fonts'
import breakpoints from '../../styles/breakpoints'
import {fadeIn, extraLong} from '../../styles/animation'
import {motion} from 'framer-motion'

const FeaturedTitle = styled(motion.h2).attrs({ 
  ...fadeIn, 
  ...extraLong 
})`
  font-family: ${primaryTitleFont};
  font-weight: 900;
  font-style: italic;
  font-size: 3rem;

  ${breakpoints.lg} {
    font-size: 5rem;
  }

  ${breakpoints.xl} {
    font-size: 6rem;
  }
`

export default FeaturedTitle