

import frontEnd from '../static/icons/front-end.svg'
import webMaintenance from '../static/icons/web-maintenance.svg'
import workspace from '../static/icons/workspace.svg'

const specialFeatures = [{
  title: 'Full Stack Development',
  image: frontEnd,
  description: `I've worked with large and small business to create solutions that deliever value to their customers. From IBM to Non-profit art studios exploring community wellnes. Let me help you create solutions to your technical problems.`
}, {
  title: 'React and Node code auditing',
  image: webMaintenance,
  description: `Modern problems require modern solutions. I work with bleeding edge web-technologies to create robust and engaging expriences.`
}, {
  title: 'Hands on approach',
  image: workspace,
  description: `When you work with me, you work with: me.`
}]

export default specialFeatures